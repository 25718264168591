<div id="container-1" class="container">
    <ng-container>
        <nice-sidebar name="navbar" class="navbar-nice-sidebar" lockedOpen="gt-md"  *ifRole="[AccountRoles.Admin, AccountRoles.Commissioner]">
            <gmi-navbar class="left-navbar"></gmi-navbar>
        </nice-sidebar>

        <div id="container-2" class="container">
            <gmi-toolbar></gmi-toolbar>
            <div id="container-3" class="container">
                <div class="content" fxLayout="column">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <gmi-bottombar [ngClass.lt-sm]="'small-screen'" *ifRole="[AccountRoles.ConvenienceStore]"></gmi-bottombar>
        </div>
    </ng-container>
</div>
