import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../api/api.service";
import { FileUtils } from "../../../../../utils/file.utils";

@Injectable()
export class ToolbarService {
    constructor(private api: ApiService) {}

    public async printQrCode(): Promise<void> {
        const result = await this.api.convenienceStore.printQrCode().toPromise();
        FileUtils.openPrintableWindow(result);
    }
}
