import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { GmiApi } from "../gmi.api";
import { ConvenienceStoreExpectedProducts } from "./models/convenience-store-expected-products.model";
import { ExpectedProductsDto } from "./dto/expected-products.dto";

export class ConvenienceStoreExpectedProductsApi extends GmiApi {
    constructor(http: HttpClient, id: number) {
        super(`convenience-store/${id}/expected-product`, http);
    }

    public getAllFromConvenienceStoreId(): Observable<ConvenienceStoreExpectedProducts[]> {
        return this.get<ConvenienceStoreExpectedProducts[]>("");
    }

    public update(productId: number, dto: ExpectedProductsDto): Observable<ConvenienceStoreExpectedProducts> {
        return this.put<ConvenienceStoreExpectedProducts>(`${productId}`, dto);
    }
}
