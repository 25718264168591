import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "gmi-code-scanner-view",
    templateUrl: "code-scanner-view.template.html",
    styleUrls: ["code-scanner-view.style.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CodeScannerViewComponent),
            multi: true
        }
    ]
})
export class CodeScannerViewComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Output()
    public enabled = new EventEmitter<boolean>();

    public code: string;
    public loading = false;
    public loadingInterval: any;

    @ViewChild(ZXingScannerComponent)
    public zXingScannerComponent: ZXingScannerComponent;

    private propagate: (code: string) => void = () => {};

    public ngOnInit(): void {
        // Hack to know is the scanner is loading or not.
        this.loadingInterval = setInterval(() => {
            this.loading = this.zXingScannerComponent?.isAutostarting ?? true;
            this.enabled.emit(!this.loading);
        }, 100);
    }

    public ngOnDestroy() {
        if (this.loadingInterval) {
            clearInterval(this.loadingInterval);
        }
    }

    public onScanned(code: string) {
        if (code !== this.code) {
            // Their bar codes always have exactly 12 digits when scanned using the EYOYO scanner. When using the camera (this library)
            // we receive 13 digits where the leading is often 0 (seems useless) so here we strip it.
            if (code.length > 12) {
                code = code.slice(code.length - 12);
            }

            this.propagate(code);
        }
    }

    public registerOnChange(fn: (code: string) => void): void {
        this.propagate = fn;
    }

    public registerOnTouched(fn: (code: string) => void): void {
        // NO-OP
    }

    public writeValue(code: string): void {
        this.code = code;
    }

    public searchProduct(): void {
        this.propagate(this.code);
    }
}
