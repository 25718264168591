import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { AppStore } from "./app.store";
import { NavigationService } from "../navigation/navigation.service";
import * as LogRocket from "logrocket";
import { UserInfo, UserRoles } from "../api/gmi/auth/models/user-info.model";

@Injectable({
    providedIn: "root"
})
export class AppService {
    constructor(
        private appStore: AppStore,
        private api: ApiService,
        private router: Router,
        private navigationService: NavigationService
    ) {}

    public loadServerState() {
        this.appStore.setLoading(true);
        this.api.info.getInfo()
            .pipe(
                tap((serverState) => {
                    this.appStore.update({ serverState });
                    this.appStore.setLoading(false);
                }),
                catchError((e) => {
                    this.appStore.setError(e);
                    this.appStore.setLoading(false);
                    return of(e);
                })
            ).subscribe();
        this.appStore.setLoading(false);
    }

    public async loadUserInfo(): Promise<void> {
        const { currentUser } = this.appStore.getValue();
        if (currentUser) {
            return;
        }

        this.appStore.setLoading(true);
        try {
            const user = await this.api.auth.getUserInfo().toPromise();
            this.logRocketIdentify(user);
            this.navigationService.setCurrentNavigation(user.role);
            this.appStore.update({ currentUser: user });
        } catch (e) {
            this.appStore.setError(e);
        } finally {
            this.appStore.setLoading(false);
        }
    }

    public async logout(): Promise<void> {
        await this.api.auth.logout().toPromise();
        this.appStore.update({ currentUser: undefined });
        this.router.navigate(["/login"]);
    }

    private logRocketIdentify(user: UserInfo) {
        switch (user.role) {
            case UserRoles.Admin:
            case UserRoles.Commissioner:
                LogRocket.identify(user.account.email, {
                    id: user.id,
                    userId: user.account.userId,
                    role: user.role
                });
                break;
            case UserRoles.ConvenienceStore:
                LogRocket.identify(user.convenienceStore.name, {
                    id: user.id,
                    role: user.role,
                    name: user.convenienceStore.name
                });
                break;
            case UserRoles.Kitchen:
                LogRocket.identify(user.kitchen.name, {
                    id: user.id,
                    role: user.role,
                    name: user.kitchen.name
                });
                break;
        }
    }
}
