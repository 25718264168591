import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit";
import { PublicPageModule } from "../public-page/public-page.module";
import { PasswordLoginFormModule } from "./components/password-login-form/password-login-form.module";
import { CodeScannerModalModule } from "../../components/code-scanner/modal/code-scanner-modal.module";

import { LoginComponent } from "./login.component";
import { LoginQuery } from "./store/login.query";
import { LoginService } from "./store/login.service";
import { LoginStore } from "./store/login.store";
import { CodeScannerViewModule } from "../../components/code-scanner/view/code-scanner-view.module";

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        PublicPageModule,
        TranslateModule,
        MatButtonModule,
        NiceLoadingSpinnerModule,
        PasswordLoginFormModule,
        MatDialogModule,
        CodeScannerModalModule,
        CodeScannerViewModule
    ],
    declarations: [LoginComponent],
    providers: [
        LoginQuery,
        LoginService,
        LoginStore
    ],
    exports: [LoginComponent]
})
export class LoginModule {}
