import { Control, Required } from "@recursyve/ngx-form-generator";

export class SearchCodeForm {
    @Control()
    @Required()
    code: string;

    constructor(value?: Partial<SearchCodeForm>) {
        if (!value) {
            return;
        }
        Object.assign(this, value);
    }
}
