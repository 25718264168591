import { Component, HostListener, Input, ViewEncapsulation } from "@angular/core";
import { GeneratedFormGroup, ngxFormGeneratorFactory } from "@recursyve/ngx-form-generator";
import { UserRoles } from "../../../../api/gmi/auth/models/user-info.model";
import { BaseFormComponent } from "../../../../components/base-form/base-form.component";
import { PasswordLoginForm } from "./password-login.form";
import { KeyboardCodes } from "../../../../utils/keyboard.utils";

@Component({
    selector: "gmi-password-login-form",
    templateUrl: "password-login-form.template.html",
    styleUrls: ["password-login-form.style.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: GeneratedFormGroup,
            useFactory: ngxFormGeneratorFactory(PasswordLoginForm)
        }
    ]
})
export class PasswordLoginFormComponent extends BaseFormComponent<PasswordLoginForm> {
    @Input()
    public type: UserRoles;

    @Input()
    public error: boolean;

    constructor(formGroup: GeneratedFormGroup<PasswordLoginForm>) {
        super(formGroup);
    }

    @HostListener("keyup", ["$event"])
    public onChange(event: KeyboardEvent) {
        if (event?.code === KeyboardCodes.Enter) {
            this.clickSubmit();
        }
    }
}
