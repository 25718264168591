import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthApi } from "./gmi/auth/auth.api";
import { InfoApi } from "./gmi/info/info.api";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { ProductApi } from "./gmi/products/product.api";
import { ConvenienceStoreApi } from "./gmi/convenience-stores/convenience-store.api";
import { SettingsApi } from "./gmi/settings/settings.api";
import { InventorySnapshotApi } from "./gmi/inventory-snapshot/inventory-snapshot.api";
import { TrashedProductApi } from "./gmi/trashed-products/trashed-product.api";
import { PurchaseOrderApi } from "./gmi/purchase-orders/apis/purchase-order.api";
import { InventoryReportApi } from "./gmi/inventory-report/inventory-report.api";
import { InventoryProductApi } from "./gmi/inventory/inventory-product.api";
import { TagsApi } from "./gmi/tags/tags.api";
import { KitchensApi } from "./gmi/kitchens/kitchens.api";

@NgModule({
    providers: [
        AuthApi,
        ConvenienceStoreApi,
        InfoApi,
        InventoryProductApi,
        InventoryReportApi,
        InventorySnapshotApi,
        ProductApi,
        PurchaseOrderApi,
        SettingsApi,
        TagsApi,
        TrashedProductApi,
        KitchensApi,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ]
})
export class ApiModule {
}
