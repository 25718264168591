import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FilterApi } from "../../filter/filter.api";
import { Products } from "./models/products.model";
import { Observable } from "rxjs";
import { ProductsDto } from "./dto/products.dto";
import { FormDataUtils } from "../../../utils/form-data.utils";
import { SearchProductsDto } from "./dto/search-products.dto";
import { ExportExpectedProductsDto } from "./dto/export-expected-products.dto";
import { FilterDto } from "./dto/filter.dto";

@Injectable()
export class ProductApi extends FilterApi<Products> {
    constructor(
        protected http: HttpClient
    ) {
        super("product", http);
    }

    public getById(id: number): Observable<Products> {
        return this.get<Products>(`${id}`);
    }

    public getAll(): Observable<Products[]> {
        return this.get<Products[]>("");
    }

    public filterPreparedMealsFromConvenienceStoreId(id: number, dto: FilterDto): Observable<Products[]> {
        const query = `value=${dto.search}&start=${dto.start}&size=${dto.size}`;
        return this.get<Products[]>(`prepared-meal/convenience-store/${id}/filter?${query}`);
    }

    public findProductByCode(code: string, allowArchivedProduct = false): Observable<Products> {
        const query =  `?allowArchivedProduct=${allowArchivedProduct}`;
        return this.get<Products>(`code/${code}${query}`);
    }

    public isCodeAlreadyUsed(code: string, excludedId?: number): Observable<boolean> {
        const query = excludedId ? `?excludedId=${excludedId}` : "";
        return this.head(`code/${code}${query}`);
    }

    public remove(id: number): Observable<void> {
        return this.delete<void>(`${id}`);
    }

    public create(image: File, dto: ProductsDto): Observable<Products> {
        const data = new FormData();
        if (image) {
            data.append("image", image);
        }

        FormDataUtils.fromObject(dto, data);

        return this.post<Products>("", data, {
            headers: { "ngsw-bypass": "true" }
        });
    }

    public update(id: number, image: File, dto: ProductsDto): Observable<Products> {
        const data = new FormData();
        if (image) {
            data.append("image", image);
        }

        FormDataUtils.fromObject(dto, data);

        return this.put<Products>(`${id}`, data, {
            headers: { "ngsw-bypass": "true" }
        });
    }

    public updateAvailability(id: number): Observable<void> {
        return this.put<void>(`${id}/availability`);
    }

    public search(dto: SearchProductsDto): Observable<Products[]> {
        let query = `value=${dto.value}`;
        query += dto.limit ? `&limit=${dto.limit}` : "";
        return this.get<Products[]>(`search?${query}`);
    }

    public exportAsXlsx(dto: ExportExpectedProductsDto): Observable<Blob> {
        return this.http.post(this.url("export/xlsx"), dto, {
            responseType: "blob"
        });
    }
}
