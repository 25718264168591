import { Component, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "gmi-bottombar",
    templateUrl: "bottombar.template.html",
    styleUrls: ["bottombar.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class BottombarComponent {

}
