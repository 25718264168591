import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CodeScannerModalData } from "./code-scanner-modal.data";
import { GeneratedFormGroup, ngxFormGeneratorFactory } from "@recursyve/ngx-form-generator";
import { SearchCodeForm } from "./forms/search-code.form";
import { BaseFormComponent } from "../../base-form/base-form.component";
import { KeyboardCodes } from "../../../utils/keyboard.utils";

@Component({
    selector: "gmi-code-scanner-modal",
    templateUrl: "code-scanner-modal.template.html",
    styleUrls: ["code-scanner-modal.style.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: GeneratedFormGroup,
            useFactory: ngxFormGeneratorFactory(SearchCodeForm)
        }
    ]
})
export class CodeScannerModalComponent extends BaseFormComponent<SearchCodeForm> implements OnInit {
    public placeholder = "components.code_scanner_modal.product_code";
    public icon = "qrcode";
    public code: string;
    public enable = false;

    constructor(
        private matDialogRef: MatDialogRef<CodeScannerModalComponent, string | null>,
        public formGroup: GeneratedFormGroup<SearchCodeForm>,
        @Inject(MAT_DIALOG_DATA) public data: CodeScannerModalData
    ) {
        super(formGroup);
    }

    @HostListener("keyup", ["$event"])
    public onChange(event: KeyboardEvent) {
        if (event?.code === KeyboardCodes.Enter) {
            this.clickSearchCode();
        }
    }

    public ngOnInit() {
        super.ngOnInit();
        if (this.data) {
            this.icon = this.data.icon;
            this.placeholder = this.data.placeholder;
        }
    }

    public clickCancel() {
        this.matDialogRef.close(null);
    }

    public onCodeScanned(scan: string) {
        if (scan?.length) {
            this.matDialogRef.close(scan);
        }
    }

    public clickSearchCode() {
        if (this.formGroup.valid) {
            this.matDialogRef.close(this.formGroup.getRawValue().code);
        }
        this.formGroup.markAllAsTouched();
    }

    public isEnable(event: boolean) {
        this.enable = event;
    }

    public close() {
        this.matDialogRef.close(null);
    }
}
