import { Routes } from "@angular/router";
import { DashboardComponent } from "./features/dashboard/dashboard.component";
import { DASHBOARD_ROUTES } from "./features/dashboard/dashboard.routes";
import { LoginComponent } from "./features/login/login.component";
import { AuthenticatedGuard } from "./guards/authenticated.guard";
import { NotAuthenticatedGuard } from "./guards/not-authenticated.guard";

export const ROUTES: Routes = [
    // Main redirect
    { path: "", redirectTo: "login", pathMatch: "full" },

    // App components
    {
        path: "dashboard",
        component: DashboardComponent,
        children: DASHBOARD_ROUTES,
        canActivate: [AuthenticatedGuard]
    },

    {
        path: "login",
        component: LoginComponent,
        canActivate: [NotAuthenticatedGuard]
    },

    // Handle all other routes
    { path: "**", redirectTo: "dashboard" }
];
