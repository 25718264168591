import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { AppQuery } from "../store/app.query";

// tslint:disable-next-line:directive-selector
@Directive({ selector: "[ifRole]" })
export class IfRoleDirective implements OnInit, OnDestroy {
    private currentUser$: Subscription;

    @Input("ifRole")
    public roles: string[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private changeDetectorRef: ChangeDetectorRef,
        private appQuery: AppQuery
    ) {}

    public ngOnInit(): void {
        this.currentUser$ = this.appQuery.selectUserInfo()
            .pipe(
                tap(() => this.viewContainer.clear()),
                filter((user) => !!user && this.roles.some((r) => user.role === r))
            )
            .subscribe(() => {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.currentUser$.unsubscribe();
    }
}
