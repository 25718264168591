<div class="dialog-content-wrapper modal-layout" fxLayout="column">
    <gmi-code-scanner-view
        (enabled)="isEnable($event)"
        [ngModel]="code"
        (ngModelChange)="onCodeScanned($event)"
    ></gmi-code-scanner-view>
    <div [formGroup]="formGroup" class="form">
        <div fxLayout="row" fxFlex="100" fxLayoutGap="center center">
            <mat-form-field floatLabel="never" fxFlex="80">
                <input
                    matInput
                    id="password"
                    [type]="icon === 'qrcode' ? 'number' : 'password'"
                    formControlName="code"
                    [placeholder]="placeholder | translate"
                >
            </mat-form-field>
            <div fxFlex="15" class="pt-12 pl-20" fxLayoutAlign="end">
                <button (click)="clickSearchCode()" mat-icon-button>
                    <i [class]="'far fa-' + icon"></i>
                </button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="enable">
        <div fxLayout="row" fxLayoutAlign="center center" class="w-100-p pb-20">
            <button (click)="close()" class="close" mat-flat-button color="accent">
                {{ "general.close" | translate }}
            </button>
        </div>
    </ng-container>
</div>
