import { HttpClient } from "@angular/common/http";
import { FilterApi } from "../../filter/filter.api";
import { InventoryReportProducts } from "./models/inventory-report-products.model";
import { Observable } from "rxjs";
import { InventoryReports } from "./models/inventory-reports.model";
import { UpsertInventoryReportsDto } from "./dto/upsert-inventory-reports.dto";
import { QuantityDto } from "../products/dto/quantity.dto";

export class InventoryReportProductsApi extends FilterApi<InventoryReportProducts> {
    constructor(http: HttpClient, id: number) {
        super(`inventory-report/${id}/product`, http);
    }

    public getInventoryReportProducts(): Observable<InventoryReportProducts[]> {
        return this.get<InventoryReportProducts[]>("");
    }

    public addProduct(productId: number): Observable<InventoryReportProducts> {
        return this.post<InventoryReportProducts>(`${productId}`);
    }

    public updateInventoryReportProductsQuantity(productId: number, dto: QuantityDto): Observable<InventoryReports> {
        return this.put<InventoryReports>(`${productId}`, dto);
    }

    public update(dto: UpsertInventoryReportsDto): Observable<InventoryReports> {
        return this.put<InventoryReports>("", dto);
    }

    public deleteInventoryReportProducts(id: number): Observable<void> {
        return this.delete<void>(`${id}`);
    }
}
