<mat-form-field fxFlex>
    <mat-label>{{ label | translate }}</mat-label>
    <mat-chip-list #chipList>
        <mat-chip
            (click)="select(i)"
            (removed)="validateRemove(i)"
            *ngFor="let item of items; let i = index"
            [removable]="true"
            [selectable]="true"
        >
            {{ item }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
            (matChipInputTokenEnd)="add($event)"
            [matChipInputAddOnBlur]="true"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="matChipInputSeparatorKeyCodes"
            matInput
        >
    </mat-chip-list>
</mat-form-field>
