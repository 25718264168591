import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { GmiApi } from "../gmi.api";
import { InventorySnapshotProducts } from "./models/inventory-snapshot-products.model";
import { ProductQuantityDto } from "../products/dto/product-quantity.dto";

export class InventorySnapshotProductsApi extends GmiApi {
    constructor(http: HttpClient, id: number) {
        super(`inventory-snapshot/${id}/product`, http);
    }

    public getAllFromConvenienceStoreId(): Observable<InventorySnapshotProducts[]> {
        return this.get<InventorySnapshotProducts[]>("");
    }

    public upsert(dto: ProductQuantityDto): Observable<InventorySnapshotProducts> {
        return this.post<InventorySnapshotProducts>("", dto);
    }
}
