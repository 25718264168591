import { NiceNavigation } from "@recursyve/nice-ui-kit";

export const commissionerNavigation: NiceNavigation[] = [
    {
        id: "home",
        type: "group",
        children: [
            {
                id: "inventory-report",
                translate: "navigation.inventory_report",
                type: "item",
                iconType: "fontawesome",
                icon: "fas fa-file-invoice",
                url: "/dashboard/inventory-report"
            },
            {
                id: "purchase-order/to-deliver",
                translate: "navigation.purchase_orders_to_deliver",
                type: "item",
                iconType: "fontawesome",
                icon: "fas fa-truck-loading",
                url: "/dashboard/purchase-order/to-deliver"
            }
        ]
    }
];
