import { Control, Email, Required } from "@recursyve/ngx-form-generator";

export class PasswordLoginForm {
    @Control()
    @Email()
    @Required()
    email: string;

    @Control()
    @Required()
    password: string;
}
