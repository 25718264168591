import { HashLocationStrategy, LOCATION_INITIALIZED, LocationStrategy } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouterModule } from "@angular/router";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NiceToastModule } from "@recursyve/nice-ui-kit";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { AkitaNgRouterStoreModule } from "@datorama/akita-ng-router-store";
import * as Sentry from "@sentry/angular";
import { AppComponent } from "./app.component";
import { ROUTES } from "./app.routes";
import { DashboardModule } from "./features/dashboard/dashboard.module";
import { ApiModule } from "./api/api.module";
import { LoginModule } from "./features/login/login.module";
import { AuthenticatedGuard } from "./guards/authenticated.guard";
import { NotAuthenticatedGuard } from "./guards/not-authenticated.guard";
import { AppStore } from "./store/app.store";
import { AppQuery } from "./store/app.query";
import { GlobalErrorHandler } from "./handlers/global-error.handler";
import { environment } from "../environments/environment";
import { I18nService } from "./providers/i18n.service";
import { NgxMaskModule } from "ngx-mask";
import { RedirectGuard } from "./guards/redirect.guard";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { ServiceWorkerModule } from '@angular/service-worker';
import { NGX_FORM_GENERATOR_ASYNC_VALIDATORS } from "@recursyve/ngx-form-generator";
import { CodeNotUsedValidator } from "./validators/code-not-used.validator";
import { NipNotUsedValidator } from "./validators/nip-not-used.validator";

export function appInitializerFactory(translate: TranslateService, i18nService: I18nService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(async () => {
                const lang = i18nService.getDefaultLang();
                translate.setDefaultLang(lang);
                try {
                    await translate.use(lang).toPromise();
                } finally {
                    resolve();
                }
            });
        });
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        RouterModule.forRoot(ROUTES),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxMaskModule.forRoot(),
        MatNativeDateModule,
        MatButtonModule,
        MatIconModule,
        ApiModule,
        DashboardModule,
        LoginModule,
        NiceToastModule,
        environment.production ? [] : AkitaNgDevtools,
        AkitaNgRouterStoreModule
    ],
    providers: [
        AppStore,
        AppQuery,
        I18nService,
        AuthenticatedGuard,
        NotAuthenticatedGuard,
        RedirectGuard,
        {
            provide: MatPaginatorIntl,
            deps: [I18nService],
            useFactory: (i18nService: I18nService) => i18nService.getPaginatorIntl()
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, I18nService, Injector],
            multi: true
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: NGX_FORM_GENERATOR_ASYNC_VALIDATORS, useClass: CodeNotUsedValidator, multi: true },
        { provide: NGX_FORM_GENERATOR_ASYNC_VALIDATORS, useClass: NipNotUsedValidator, multi: true }
    ],
    exports: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule {}
