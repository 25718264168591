import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { InventorySettingsModalState, InventorySettingsModalStore } from "./inventory-settings-modal.store";
import { Observable } from "rxjs";
import { InventorySettingConstants } from "../../../../../api/gmi/settings/models/inventory-settings.model";

@Injectable()
export class InventorySettingsModalQuery extends Query<InventorySettingsModalState> {
    constructor(store: InventorySettingsModalStore) {
        super(store);
    }

    public selectInventorySettings(): Observable<InventorySettingConstants> {
        return this.select(state => state.inventorySettings);
    }
}
