import { Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { COMMA, ENTER, SPACE } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { filter } from "rxjs/operators";
import { NiceAlertService } from "@recursyve/nice-ui-kit";
import { isEmail } from "../../utils/regex.utils";

@Component({
    selector: "gmi-chip-list-input",
    templateUrl: "chip-list-input.template.html",
    styleUrls: ["chip-list-input.style.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipListInputComponent),
            multi: true
        }
    ]
})
export class ChipListInputComponent implements ControlValueAccessor {
    public matChipInputSeparatorKeyCodes = [COMMA, ENTER, SPACE];
    @Input()
    public deleteWithWarning: boolean;
    @Input()
    public warningTitle: string;
    @Input()
    public warningMessage: string;
    @Input()
    public warningApply: string;
    @Input()
    public warningCancel: string;
    @Input()
    public label: string;
    @Output()
    public onAdded = new EventEmitter<string>();
    @Output()
    public onSelected = new EventEmitter<string>();
    @Output()
    public onRemoved = new EventEmitter<string>();

    private _items: string[];

    constructor(private alertService: NiceAlertService) {
    }

    public get items(): string[] {
        return this._items ?? [];
    }

    public set items(items: string[]) {
        this._items = items;
        this.propagate(this.items);
    }

    public add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        const item = (value ?? "").trim();
        let added = false;
        if (item && !this.items.includes(item) && isEmail(value)) {
            this.items = [...this.items, value.trim()];
            added = true;
        }

        if (input && added) {
            this.onAdded.emit(input.value);
            input.value = "";
        }
    }

    public validateRemove(index: number): void {
        if (this.deleteWithWarning) {
            this.alertService.warning(this.warningTitle, this.warningMessage, [
                this.warningApply,
                this.warningCancel
            ]).pipe(
                filter((shouldDelete) => shouldDelete)
            ).subscribe(async () => {
                this.remove(index);
            });
        } else {
            this.remove(index);
        }
    }

    public select(i: number) {
        this.onSelected.emit(this.items[i]);
    }

    public writeValue(items: string[]): void {
        this.items = items;
    }

    public registerOnChange(fn: (control: string[]) => void): void {
        this.propagate = fn;
    }

    public registerOnTouched(fn: any): void {
        // NO-OP
    }

    private propagate: (list: string[]) => void = () => {};

    private remove(index: number): void {
        this.onRemoved.emit(this.items[index]);
        const items = this.items;
        items.splice(index, 1);
        this.items = [...items];
    }

}
