import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../api/api.service";

@Injectable()
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private api: ApiService,
        private router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.api.auth.getUserInfo().pipe(
            map((user) => {
                if (!user) {
                    return this.router.createUrlTree(["/login"]);
                }
                return true;
            })
        );
    }
}
