import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { UserRoles } from "../../../api/gmi/auth/models/user-info.model";

export interface LoginState {
    selectedRole: UserRoles;
}

export const initialValue: LoginState = {
    selectedRole: null
};

@Injectable()
@StoreConfig({ name: "login", resettable: true })
export class LoginStore extends Store<LoginState> {
    constructor() {
        super(initialValue);
    }
}
