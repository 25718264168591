import { Component, OnDestroy } from "@angular/core";
import { OperatorFunction } from "rxjs";
import { map } from "rxjs/operators";
import { UserRoles } from "../../api/gmi/auth/models/user-info.model";
import { AppQuery } from "../../store/app.query";
import { PasswordLoginForm } from "./components/password-login-form/password-login.form";
import { LoginQuery } from "./store/login.query";
import { LoginService } from "./store/login.service";
import { MatDialog } from "@angular/material/dialog";
import { CodeScannerModalComponent } from "../../components/code-scanner/modal/code-scanner-modal.component";
import { CodeScannerModalData } from "../../components/code-scanner/modal/code-scanner-modal.data";

@Component({
    selector: "gmi-login",
    templateUrl: "login.template.html",
    styleUrls: ["login.style.scss"]
})
export class LoginComponent implements OnDestroy {
    public serverState$ = this.appQuery.selectServerState();
    public serverStateError$ = this.appQuery.selectError();

    public loading$ = this.query.selectLoading();
    public error$ = this.query.selectError();
    public selectedRole$ = this.query.selectSelectedRole();
    public needsPinLogin$ = this.selectedRole$.pipe(this.roleInArray([UserRoles.ConvenienceStore, UserRoles.Kitchen]));
    public needsPasswordLogin$ = this.selectedRole$.pipe(this.roleInArray([UserRoles.Admin, UserRoles.Commissioner]));

    public get UserRoles(): typeof UserRoles {
        return UserRoles;
    }

    constructor(private appQuery: AppQuery, private matDialog: MatDialog, private query: LoginQuery, private service: LoginService) {}

    public ngOnDestroy(): void {
        this.service.reset();
    }

    public setSelectedRole(role: UserRoles): void {
        this.service.setSelectedRole(role);
        if (role === UserRoles.ConvenienceStore || role === UserRoles.Kitchen) {
            this.openCodeScannerModal();
        }
    }

    public resetSelectedRole(): void {
        this.service.reset();
    }

    public openCodeScannerModal(): void {
        this.matDialog.open<CodeScannerModalComponent, CodeScannerModalData, string>(CodeScannerModalComponent, {
            panelClass: "code-scanner-modal",
            data: {
                placeholder: "components.code_scanner_modal.nip",
                icon: "sign-in"
            },
            disableClose: true
        }).afterClosed().subscribe(async (nip: string | undefined) => {
            if (nip) {
                await this.service.loginWithNip(nip);
            }
        });
    }

    public loginWithPassword(form: PasswordLoginForm): void {
        this.service.loginWithPassword(form.email, form.password);
    }

    private roleInArray(roles: UserRoles[]): OperatorFunction<UserRoles | undefined, boolean> {
        return (source) => source.pipe(
            map((x) => roles.some((role) => role === x))
        );
    }
}
