import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NiceSidebarService } from "@recursyve/nice-ui-kit";
import { map } from "rxjs/operators";
import { AppQuery } from "../../../../store/app.query";
import { AppService } from "../../../../store/app.service";
import { MatDialog } from "@angular/material/dialog";
import { InventorySettingsModalComponent } from "../../settings/inventory-settings-modal/inventory-settings-modal.component";
import { ToolbarService } from "./providers/toolbar.service";
import { AccountRoles } from "../../../../api/gmi/accounts/models/accounts.model";

@Component({
    selector: "gmi-toolbar",
    templateUrl: "toolbar.template.html",
    styleUrls: ["./toolbar.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {
    public currentUser$ = this.appQuery.selectUserInfo();
    public toolbarClasses: string[] = [];
    public AccountRoles = AccountRoles;

    constructor(
        private fuseSidebarService: NiceSidebarService,
        private appQuery: AppQuery,
        private appService: AppService,
        private matDialog: MatDialog,
        private convenienceStoresService: ToolbarService
    ) {
    }

    public ngOnInit(): void {
        this.currentUser$.pipe(
            map((x) => [x?.role ?? ""])
        ).subscribe((classes) => {
            this.toolbarClasses = classes;
        });
    }

    public toggleSidebarOpen(key: string) {
        this.fuseSidebarService.getSidebar(key).toggleOpen();
    }

    public async logout() {
        this.appService.logout();
    }

    public openSetting(): void {
        this.matDialog.open<InventorySettingsModalComponent>(
            InventorySettingsModalComponent, {
                panelClass: "inventory-snapshot-settings"
            }
        );
    }

    public async printQrCode(): Promise<void> {
        await this.convenienceStoresService.printQrCode();
    }
}
