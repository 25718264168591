import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { AsyncValidator, registerAsyncValidatorDecorator } from "@recursyve/ngx-form-generator";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { AppStore } from "../store/app.store";

export function NipNotUsed() {
    return registerAsyncValidatorDecorator({
        name: "NipNotUsedValidator"
    });
}

@Injectable()
export class NipNotUsedValidator extends AsyncValidator {
    public name = "NipNotUsedValidator";

    constructor(
        private apiService: ApiService
    ) {
        super();
    }

    public validate(control: AbstractControl): Observable<ValidationErrors | null> {
        return this.apiService.convenienceStore.isNipAlreadyUsed(control.value).pipe(
            map(used => {
                if (!used) {
                    return null;
                }

                return {
                    nip_used: true
                };
            })
        );
    }
}
