import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GmiApi } from "../gmi.api";
import { DateFilterDto } from "../inventory-snapshot/dto/inventory-snapshot.dto";
import { HttpUtils } from "../../../utils/http.utils";
import { ProductQuantityDto } from "../products/dto/product-quantity.dto";
import { TrashedProducts } from "./models/trashed.products";

@Injectable()
export class TrashedProductApi extends GmiApi {
    constructor(protected http: HttpClient) {
        super("trashed-product", http);
    }

    public upsert(dto: ProductQuantityDto): Observable<void> {
        return this.post<void>("", dto);
    }

    public deleteTrashedProduct(id: number): Observable<void> {
        return this.delete<void>(`${id}`);
    }
}
