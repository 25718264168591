import { Injectable } from "@angular/core";
import { AuthApi } from "./gmi/auth/auth.api";
import { InfoApi } from "./gmi/info/info.api";
import { ProductApi } from "./gmi/products/product.api";
import { ConvenienceStoreApi } from "./gmi/convenience-stores/convenience-store.api";
import { SettingsApi } from "./gmi/settings/settings.api";
import { InventorySnapshotApi } from "./gmi/inventory-snapshot/inventory-snapshot.api";
import { TrashedProductApi } from "./gmi/trashed-products/trashed-product.api";
import { PurchaseOrderApi } from "./gmi/purchase-orders/apis/purchase-order.api";
import { InventoryReportApi } from "./gmi/inventory-report/inventory-report.api";
import { InventoryProductApi } from "./gmi/inventory/inventory-product.api";
import { TagsApi } from "./gmi/tags/tags.api";
import { KitchensApi } from "./gmi/kitchens/kitchens.api";

@Injectable({ providedIn: "root" })
export class ApiService {
    constructor(
        public readonly auth: AuthApi,
        public readonly convenienceStore: ConvenienceStoreApi,
        public readonly info: InfoApi,
        public readonly inventoryProduct: InventoryProductApi,
        public readonly inventoryReport: InventoryReportApi,
        public readonly inventorySnapshot: InventorySnapshotApi,
        public readonly product: ProductApi,
        public readonly purchaseOrder: PurchaseOrderApi,
        public readonly setting: SettingsApi,
        public readonly tag: TagsApi,
        public readonly trashedProduct: TrashedProductApi,
        public readonly kitchen: KitchensApi,
    ) {}
}
