export class FileUtils {
    public static window = null;

    public static async getFileFromUrl(url?: string, name = ""): Promise<File | undefined> {
        if (!url) {
            return undefined;
        }
        const res = await fetch(url);
        return new File([await res.blob()], name);
    }

    public static async selectImage(multiple = false): Promise<File | File[] | null | never[]> {
        return new Promise(resolve => {
            const input = document.createElement("input");
            input.type = "file";
            input.multiple = multiple;
            input.accept = "image/*";

            input.onchange = () => {
                if (input.files) {
                    const files = Array.from(input.files);
                    return resolve(multiple ? files : files[0]);
                }

                resolve(multiple ? [] : null);
            };

            input.click();
        });
    }

    public static downloadFile(fileName: string, content: Blob, downloadLink?: HTMLAnchorElement): void {
        if (!downloadLink) {
            downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            downloadLink.style.display = "none";
        }

        const dataUrl = window.URL.createObjectURL(content);
        downloadLink.href = dataUrl;
        downloadLink.download = fileName;
        downloadLink.click();
        window.URL.revokeObjectURL(dataUrl);
        document.body.removeChild(downloadLink);
    }

    public static openPrintableWindow(content: string): void {
        const win = window.open("");
        if (!win) {
            return;
        }

        win.document.open();
        win.document.write(content);
        win.document.close();
    }
}
