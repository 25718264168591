import { Injectable } from "@angular/core";
import { adminNavigation } from "./admin.navigation";
import { NiceNavigationService } from "@recursyve/nice-ui-kit";
import { UserRoles } from "../api/gmi/auth/models/user-info.model";
import { commissionerNavigation } from "./commissioner.navigation";

@Injectable({ providedIn: "root" })
export class NavigationService {
    constructor(private niceNavigationService: NiceNavigationService) {}

    public async init() {
        this.niceNavigationService.register("admin", adminNavigation);
        this.niceNavigationService.register("commissioner", commissionerNavigation);
    }

    public setCurrentNavigation(role: UserRoles) {
        this.niceNavigationService.setCurrentNavigation(role);
    }
}
