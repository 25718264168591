import { DatabaseEntities } from "../../../models/database-entities.model";

export enum AccountRoles {
    Admin = "admin",
    Commissioner = "commissioner",
    ConvenienceStore = "convenience_store"
}

export interface Accounts extends DatabaseEntities {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    role: AccountRoles;
}
