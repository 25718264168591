import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InventoryProducts } from "./models/inventory-products.model";
import { FilterApi } from "../../filter/filter.api";

@Injectable()
export class InventoryProductApi extends FilterApi<InventoryProducts> {
    constructor(protected http: HttpClient) {
        super("inventory-product", http);
    }
}
