import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    FilterConfigurationModel,
    FilterQueryModel,
    FilterResultModel,
    SelectFilterValue
} from "./models/filter.model";
import { GmiApi } from "../gmi/gmi.api";

export class FilterApi<T> extends GmiApi {
    constructor(path: string, protected http: HttpClient) {
        super(path, http);
    }

    public filter(queryModel: FilterQueryModel): Observable<FilterResultModel<T>> {
        return this.http.post<FilterResultModel<T>>(this.url("filter"), queryModel);
    }

    public filterCount(queryModel: FilterQueryModel): Observable<number> {
        return this.http.post<number>(this.url("filter-count"), queryModel);
    }

    public downloadData(type: string, queryModel: FilterQueryModel): Observable<Blob> {
        return this.http.post(this.url(`download/${type}`), queryModel, { responseType: "blob" });
    }

    public getPrintableHtml(queryModel: FilterQueryModel): Observable<string> {
        return this.http.post(this.url(`download/html`), queryModel, { responseType: "text" });
    }

    public getFilterConfig(): Observable<FilterConfigurationModel[]> {
        return this.http.get<FilterConfigurationModel[]>(this.url("filter/config"));
    }

    public searchFilterValue(id: string, value: string): Observable<SelectFilterValue[]> {
        if (value === undefined) {
            value = "";
        }

        return this.http.get<SelectFilterValue[]>(this.url(`filter/config/value?id=${id}&value=${value}`));
    }

    public searchFilterResourceValue(id: string, resourceId: number): Observable<SelectFilterValue> {
        return this.http.get<SelectFilterValue>(this.url(`filter/config/id?id=${id}&resourceId=${resourceId}`));
    }
}
