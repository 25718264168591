import { Component } from "@angular/core";
import { niceAnimations } from "@recursyve/nice-ui-kit";

@Component({
    selector: "gmi-public-page",
    templateUrl: "public-page.template.html",
    styleUrls: ["public-page.style.scss"],
    animations: niceAnimations
})
export class PublicPageComponent {}
