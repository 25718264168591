<div class="navbar-header">
    <div class="logo">
        <img class="logo-icon" src="../../../../../assets/images/logo-white.png"/>
    </div>
    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button>
</div>

<div class="navbar-content">
    <nice-navigation class="material2" layout="vertical"></nice-navigation>
</div>
