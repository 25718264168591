import { Routes } from "@angular/router";
import { RedirectComponent } from "./redirect/redirect.component";
import { RedirectGuard } from "../../guards/redirect.guard";

export const DASHBOARD_ROUTES: Routes = [
    // Main redirect
    { path: "", component: RedirectComponent,  canActivate: [RedirectGuard], pathMatch: "full" },

    {
        path: "product",
        loadChildren: () => import("./products/products.module").then(x => x.ProductsModule)
    },
    {
        path: "convenience-store",
        loadChildren: () => import("./convenience-stores/convenience-stores.module").then(x => x.ConvenienceStoresModule)
    },
    {
        path: "daily-order",
        loadChildren: () => import("./daily-orders/daily-orders.module").then(x => x.DailyOrdersModule)
    },
    {
        path: "inventory-report",
        loadChildren: () => import("./inventory-reports/inventory-reports.module").then(x => x.InventoryReportsModule)
    },
    {
        path: "inventory-snapshot",
        loadChildren: () => import("./inventory-snapshot/inventory-snapshot.module").then(x => x.InventorySnapshotModule)
    },
    {
        path: "purchase-order",
        loadChildren: () => import("./purchase-orders/purchase-orders.module").then(x => x.PurchaseOrdersModule)
    },
    {
        path: "trashed-product",
        loadChildren: () => import("./trashed-products/trashed-products.module").then(x => x.TrashedProductsModule)
    },
    {
        path: "tag",
        loadChildren: () => import("./tags/tags.module").then(x => x.TagsModule)
    }
];
