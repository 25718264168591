<div [niceLoadingOverlay]="loading$ | async" class="dialog-content-wrapper modal-layout">
    <mat-toolbar class="m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="64px">
            <span class="title dialog-title pl-8 text-truncate">
                {{ "features.dashboard.settings.inventory_snapshot.title" | translate }}
            </span>
            <button (click)="clickCancel()" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div [formGroup]="formGroup">
        <div class="padding-form" fxLayout="column" fxLayoutAlign="center">
            <div fxLayout="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="8px">
                <div fxFlex="50">
                    <mat-form-field>
                        <mat-label>{{ "features.dashboard.settings.inventory_snapshot.reminder_alert_time" | translate }}</mat-label>
                        <div fxLayout="row" fxFlex="100">
                            <input fxFlex="90" readonly matInput formControlName="time" [ngxTimepicker]="toggleTimepicker" [format]="24">
                            <div fxFlex="10" class="accent-fg pr-12" fxLayoutAlign="center center">
                                <ngx-material-timepicker-toggle
                                    [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
                                <ngx-material-timepicker #toggleTimepicker>
                                </ngx-material-timepicker>
                            </div>
                        </div>
                    </mat-form-field>
                </div>
                <div fxFlex="50">
                    <div>
                        <gmi-chip-list-input
                            [deleteWithWarning]="false"
                            [warningApply]="'general.confirm' | translate"
                            [warningCancel]="'general.cancel' | translate"
                            [warningMessage]="'features.dashboard.settings.inventory_snapshot.alert.message' | translate"
                            [warningTitle]="'features.dashboard.settings.inventory_snapshot.alert.title' | translate"
                            [label]="'features.dashboard.settings.inventory_snapshot.email' | translate"
                            formControlName="email">
                        </gmi-chip-list-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
            <button (click)="save()" color="accent" mat-flat-button>
                {{ "general.save" | translate }}
            </button>
        </div>
    </div>
</div>
