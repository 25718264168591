import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import * as LogRocket from "logrocket";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.sentry.environment,
    beforeSend: (event, _) => {
        console.log(event.exception);

        if (environment.sentry.reportErrors) {
            return event;
        }

        return null;
    },
    integrations: [
        new BrowserTracing({
            tracingOrigins: [environment.apiUrl],
            routingInstrumentation: Sentry.routingInstrumentation
        })
    ]
});

if (environment.production) {
    enableProdMode();
    LogRocket.init("groupe-mondor/mondor");
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
