import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DirectivesModule } from "../../directives/directives.module";
import { DashboardComponent } from "./dashboard.component";
import { NiceSidebarModule } from "@recursyve/nice-ui-kit";
import { ToolbarModule } from "./components/toolbar/toolbar.module";
import { NavbarModule } from "./components/navbar/navbar.module";
import { BottombarModule } from "./components/bottombar/bottombar.module";
import { RedirectModule } from "./redirect/redirect.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        ToolbarModule,
        FlexLayoutModule,
        NiceSidebarModule,
        NavbarModule,
        MatCardModule,
        DirectivesModule,
        BottombarModule,
        RedirectModule
    ],
    declarations: [DashboardComponent]
})
export class DashboardModule {
}
