import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { BottombarState, BottombarStore } from "./bottombar.store";

@Injectable()
export class BottombarQuery extends Query<BottombarState> {
    constructor(store: BottombarStore) {
        super(store);
    }
}
