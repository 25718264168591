import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { gmiAnimations } from "../../../../animations/animations";
import { InventorySettingsModalQuery } from "./store/inventory-settings-modal.query";
import { MatDialogRef } from "@angular/material/dialog";
import { GeneratedFormGroup, ngxFormGeneratorFactory } from "@recursyve/ngx-form-generator";
import { InventorySettingsModalForm } from "./inventory-settings-modal.form";
import { InventorySettingsModalService } from "./store/inventory-settings-modal.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "gmi-inventory-settings-modal",
    templateUrl: "inventory-settings-modal.template.html",
    styleUrls: ["inventory-settings-modal.style.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: gmiAnimations,
    providers: [
        {
            provide: GeneratedFormGroup,
            useFactory: ngxFormGeneratorFactory(InventorySettingsModalForm)
        }
    ]
})
export class InventorySettingsModalComponent implements OnInit, OnDestroy {
    public loading$ = this.query.selectLoading();
    public inventorySettings$ = this.query.selectInventorySettings();

    private unsubscribeAll$ = new Subject<void>();

    constructor(
        private query: InventorySettingsModalQuery,
        private matDialogRef: MatDialogRef<InventorySettingsModalComponent, boolean>,
        public formGroup: GeneratedFormGroup<InventorySettingsModalForm>,
        private service: InventorySettingsModalService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.service.reset();
        await this.service.loadConstants();
        this.inventorySettings$.pipe(takeUntil(this.unsubscribeAll$)).subscribe((settings) => {
            this.formGroup.patchValue(new InventorySettingsModalForm({
                time: settings.inventoryReminderTime,
                email: JSON.parse(settings.inventoryReminderEmails)
            }));
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
    }

    public clickCancel(): void {
        this.matDialogRef.close(false);
    }

    public async save(): Promise<void> {
        if (this.formGroup.valid) {
            await this.service.update(this.formGroup.getRawValue());
            this.matDialogRef.close(true);
        }
    }
}
