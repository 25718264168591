import { environment } from "../../../environments/environment";
import { Observable, of, throwError } from "rxjs";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { ExceptionFactory } from "../exceptions/exception.factory";

export interface HttpOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
}

export class GmiApi {
    protected exceptionFactory: ExceptionFactory;
    private readonly apiUrl = environment.apiUrl;
    private readonly path: string;

    constructor(path: string, protected httpClient: HttpClient) {
        this.path = path;
    }

    protected url(route = ""): string {
        if (route === "" && this.path === "") {
            return this.apiUrl;
        }
        return `${this.apiUrl}/${this.path}/${route}`;
    }

    protected delete<T>(route = "", options?: HttpOptions): Observable<T> {
        return this.httpClient.delete<T>(this.url(route), options).pipe(
            catchError(e => this.transformError(e))
        );
    }

    protected get<T>(route = "", options?: HttpOptions): Observable<T> {
        return this.httpClient.get<T>(this.url(route), options).pipe(
            catchError(e => this.transformError(e))
        );
    }

    protected patch<T>(route = "", body?: any, options?: HttpOptions): Observable<T> {
        return this.httpClient.patch<T>(this.url(route), body, options).pipe(
            catchError(e => this.transformError(e))
        );
    }

    protected post<T>(route = "", body?: any, options?: HttpOptions): Observable<T> {
        return this.httpClient.post<T>(this.url(route), body, options).pipe(
            catchError(e => this.transformError(e))
        );
    }

    protected put<T>(route = "", body?: any, options?: HttpOptions): Observable<T> {
        return this.httpClient.put<T>(this.url(route), body, options).pipe(
            catchError(e => this.transformError(e))
        );
    }

    protected head(route = ""): Observable<boolean> {
        return this.httpClient.head(this.url(route)).pipe(
            map(_ => true),
            catchError((error) => error?.status == 404 ? of(false) : throwError(error))
        );
    }

    private transformError(error: HttpErrorResponse): Observable<any> {
        if (!this.exceptionFactory) {
            throw error;
        }
        throw this.exceptionFactory.transform(error);
    }
}

export abstract class GmiCrudApi<T, CreateDto = Partial<T>, UpdateDto = Partial<T>> extends GmiApi {
    protected constructor(path: string, http: HttpClient) {
        super(path, http);
    }

    public create(dto: CreateDto): Observable<T> {
        return this.post<T>("", dto);
    }

    public update(id: number, dto: UpdateDto): Observable<void> {
        return this.put<void>(`${id}`, dto);
    }

    public getAll(query?: HttpParams): Observable<T[]> {
        const url = query ? `?${query.toString()}` : "";
        return this.get<T[]>(url);
    }

    public deleteById(id: number): Observable<void> {
        return this.delete<void>(`${id}`);
    }
}
