import { Store, StoreConfig } from "@datorama/akita";

export interface BottombarState {
}

const initialValue: BottombarState = {};

@StoreConfig({
    name: "bottombar",
    resettable: true
})
export class BottombarStore extends Store<BottombarState> {
    constructor() {
        super(initialValue);
    }
}
