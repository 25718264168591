import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FilterApi } from "../../filter/filter.api";
import { InventoryReports } from "./models/inventory-reports.model";
import { InventoryReportProductsApi } from "./inventory-report-products.api";
import { Observable } from "rxjs";

@Injectable()
export class InventoryReportApi extends FilterApi<InventoryReports> {
    constructor(http: HttpClient) {
        super("inventory-report", http);
    }

    public product(inventoryReportId: number): InventoryReportProductsApi {
        return new InventoryReportProductsApi(this.http, inventoryReportId);
    }

    public create(): Observable<InventoryReports> {
        return this.post<InventoryReports>("");
    }
}
