import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexModule } from "@angular/flex-layout";

import { PublicPageComponent } from "./public-page.component";

@NgModule({
    imports: [CommonModule, FlexModule],
    declarations: [PublicPageComponent],
    exports: [PublicPageComponent]
})
export class PublicPageModule {}
