import { Component, OnInit } from "@angular/core";
import { SwUpdate, SwPush } from "@angular/service-worker";
import { TranslateService } from "@ngx-translate/core";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeEn from "@angular/common/locales/en";
import { NavigationService } from "./navigation/navigation.service";
import { AppService } from "./store/app.service";
import { I18nService } from "./providers/i18n.service";
import { DateAdapter } from "@angular/material/core";

@Component({
    selector: "gmi-app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.style.scss"]
})
export class AppComponent implements OnInit {

    constructor(
        private appService: AppService,
        private dateAdapter: DateAdapter<any>,
        private navigationService: NavigationService,
        private i18nService: I18nService,
        private translateService: TranslateService,
        private updates: SwUpdate
    ) {
    }

    public async ngOnInit() {
        this.updates.unrecoverable.subscribe(x => {
            console.log(x.reason);
        });

        await this.appService.loadServerState();
        await this.navigationService.init();

        const lang = this.i18nService.getDefaultLang();
        localStorage.setItem("lang", lang);
        this.updateLang(lang);
    }

    private updateLang(lang: string) {
        this.translateService.setDefaultLang(lang);
        this.dateAdapter.setLocale(lang);
        registerLocaleData(localeFr);
        registerLocaleData(localeEn);
    }
}
