import { Control, Required } from "@recursyve/ngx-form-generator";
import { InventorySettingsDto } from "../../../../api/gmi/inventory-snapshot/dto/inventory-settings.dto";

export class InventorySettingsModalForm {
    @Control()
    @Required()
    email: string[];

    @Control()
    @Required()
    time: string;

    constructor(value?: Partial<InventorySettingsModalForm>) {
        if (!value) {
            return;
        }
        Object.assign(this, value);
    }

    public toDto(): InventorySettingsDto {
        return {
            ...this
        };
    }
}
