<div [formGroup]="formGroup" class="password-form">
    <h1>
        {{ ("features.login.components.password_login_form.title." + type) | translate }}
    </h1>

    <div class="mt-32">
        <mat-form-field class="pb-12">
            <input matInput id="email" type="email" formControlName="email"
                   [placeholder]="'features.login.components.password_login_form.email' | translate"
            >
        </mat-form-field>
        <mat-form-field class="pb-12">
            <input matInput id="password" type="password" formControlName="password"
                   [placeholder]="'features.login.components.password_login_form.password' | translate"
            >
        </mat-form-field>
        <mat-error class="mb-16 text-center" *ngIf="error">
            {{ "features.login.components.password_login_form.error" | translate }}
        </mat-error>
        <div class="mt-16" fxLayoutAlign="center">
            <button mat-raised-button color="accent" type="button" class="submit-button w-100-p" (click)="clickSubmit()">
                {{ "features.login.login" | translate }}
            </button>
        </div>
    </div>
</div>
