import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { Observable } from "rxjs";
import { UserRoles } from "../../../api/gmi/auth/models/user-info.model";
import { LoginState, LoginStore } from "./login.store";

@Injectable()
export class LoginQuery extends Query<LoginState> {
    constructor(protected store: LoginStore) {
        super(store);
    }

    public selectSelectedRole(): Observable<UserRoles | undefined> {
        return this.select("selectedRole");
    }
}
