import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FilterApi } from "../../filter/filter.api";
import { Tags } from "./models/tags.model";
import { Observable } from "rxjs";
import { TagsDto } from "./dto/tags.dto";

@Injectable()
export class TagsApi extends FilterApi<Tags> {
    constructor(
        protected http: HttpClient
    ) {
        super("tag", http);
    }

    public getAll(): Observable<Tags[]> {
        return this.get<Tags[]>("");
    }

    public create(dto: TagsDto): Observable<Tags> {
        return this.post<Tags>("", dto);
    }

    public update(id: number, dto: TagsDto): Observable<Tags> {
        return this.put<Tags>(`${id}`, dto);
    }

    public remove(id: number): Observable<void> {
        return this.delete<void>(`${id}`);
    }
}
