import { NgModule } from "@angular/core";
import { BottombarComponent } from "./bottombar.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NiceLoadingSpinnerModule, NiceNavigationModule } from '@recursyve/nice-ui-kit';
import { TranslateModule } from "@ngx-translate/core";
import { BottombarQuery } from "./store/bottombar.query";
import { BottombarService } from "./store/bottombar.service";
import { BottombarStore } from "./store/bottombar.store";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    imports: [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        RouterModule,
        CommonModule,
        FlexLayoutModule,
        RouterModule,
        NiceLoadingSpinnerModule,
        NiceNavigationModule,
        TranslateModule,
        MatDialogModule
    ],
    exports: [BottombarComponent],
    declarations: [BottombarComponent],
    providers: [
        BottombarQuery,
        BottombarService,
        BottombarStore
    ]
})
export class BottombarModule {
}
