import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { DirectivesModule } from "../../../../directives/directives.module";
import { ToolbarComponent } from "./toolbar.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FALLBACK, GravatarModule } from "ngx-gravatar";
import { FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { InventorySettingsModalModule } from "../../settings/inventory-settings-modal/inventory-settings-modal.module";
import { ToolbarService } from "./providers/toolbar.service";

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        TranslateModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        GravatarModule.forRoot({ fallback: FALLBACK.mm }),
        FlexLayoutModule,
        CommonModule,
        DirectivesModule,
        MatDialogModule,
        InventorySettingsModalModule
    ],
    providers: [ToolbarService],
    exports: [ToolbarComponent],
    declarations: [ToolbarComponent]
})
export class ToolbarModule {
}
