import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GmiApi } from "../gmi.api";
import { InventorySnapshotWeekly } from "./interfaces/inventory-snapshot-weekly.interface";
import { InventorySnapshotProductsApi } from "./inventory-snapshot-products.api";
import { InventorySnapshots } from "./models/inventory-snapshots.model";
import { HttpUtils } from "../../../utils/http.utils";
import { DateFilterDto } from "./dto/inventory-snapshot.dto";
import { SubmitInventorySnapshotDto } from "./dto/submit-inventory-snapshot.dto";

@Injectable()
export class InventorySnapshotApi extends GmiApi {
    constructor(
        protected http: HttpClient
    ) {
        super("inventory-snapshot", http);
    }

    public inventorySnapshotProducts(inventorySnapshotsId: number): InventorySnapshotProductsApi {
        return new InventorySnapshotProductsApi(this.http, inventorySnapshotsId);
    }

    public getWeeklyInventorySnapshots(dto: DateFilterDto): Observable<InventorySnapshotWeekly[]> {
        const params = HttpUtils.transformQueryParams(dto);
        return this.get<InventorySnapshotWeekly[]>(`?${params}`);
    }

    public getCurrentInventorySnapshot(): Observable<InventorySnapshots> {
        return this.post<InventorySnapshots>("");
    }

    public submitInventory(id: number, dto: SubmitInventorySnapshotDto): Observable<void> {
        return this.put<void>(`${id}`, dto);
    }
}
