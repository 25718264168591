export class FormDataUtils {
    public static fromObject(obj: any, formData = new FormData()): FormData {
        Object.entries(obj).forEach(([key, value]) => {
            if (typeof value === "object") {
                formData.append(key, JSON.stringify(value));
            } else {
                formData.append(key, value as string);
            }
        });
        return formData;
    }
}
