<div fxLayout="row" class="nav pb-12" fxFlex="100">
    <div class="option" fxLayout="column" fxLayoutAlign="center center" fxFlex="33" routerLinkActive="selected" routerLink="/dashboard/inventory-snapshot">
        <button fxLayoutAlign="center center" mat-icon-button>
            <i class="fas fa-inventory fa-fw icon-color"></i>
        </button>
        <div>
            <span>
                {{ "features.dashboard.components.bottombar.inventory_snapshots" | translate }}
            </span>
        </div>
    </div>
    <div class="option" fxLayout="column" fxLayoutAlign="center center" fxFlex="33" routerLinkActive="selected" routerLink="/dashboard/purchase-order">
        <button fxLayoutAlign="center center" mat-icon-button>
            <i class="fad fa-truck-container fa-fw icon-color"></i>
        </button>
        <div>
            <span>
                {{ "features.dashboard.components.bottombar.purchase_orders" | translate }}
            </span>
        </div>
    </div>

    <div class="option" fxLayout="column" fxLayoutAlign="center center" fxFlex="33" routerLinkActive="selected" routerLink="/dashboard/trashed-product">
        <button fxLayoutAlign="center center"  mat-icon-button>
            <i class="fas fa-dumpster fa-fw icon-color"></i>
        </button>
        <div>
            <span>
                {{ "features.dashboard.components.bottombar.trashed_products" | translate }}
            </span>
        </div>
    </div>
</div>
