import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { UserRoles } from "../api/gmi/auth/models/user-info.model";

@Injectable()
export class RedirectGuard implements CanActivate {
    constructor(
        private api: ApiService,
        private router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree> {
        return this.api.auth.getUserInfo().pipe(
            map((user) => {
                if (!user) {
                    return this.router.createUrlTree(["/login"]);
                }
                if (user.role === UserRoles.Admin) {
                    return this.router.createUrlTree(["/dashboard/product"]);
                }
                if (user.role === UserRoles.Kitchen) {
                    return this.router.createUrlTree(["/dashboard/daily-order"]);
                }
                if (user.role === UserRoles.Commissioner) {
                    return this.router.createUrlTree(["/dashboard/inventory-report"]);
                }
                if (user.role === UserRoles.ConvenienceStore) {
                    return this.router.createUrlTree(["/dashboard/purchase-order"]);
                }
                return this.router.createUrlTree(["/login"]);
            })
        );
    }
}
