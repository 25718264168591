<gmi-public-page [niceLoadingOverlay]="loading$ | async">
    <div id="login" fxLayout="column" fxFlex="100%">
        <ng-container *ngIf="serverState$ | async as serverState">
            <h3>{{ "features.login.app_name" | translate }} v{{ serverState?.version }}</h3>
            <h2 *ngIf="serverState?.mode != 'production'">{{ serverState?.mode }} version</h2>
        </ng-container>
        <ng-container *ngIf="serverStateError$ | async">
            <div class="message-box error mb-32">{{ "features.login.server_error" | translate }}</div>
        </ng-container>

        <div fxFlex="1 0 auto" fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="center center">
            <ng-container *ngIf="!(selectedRole$ | async) || (needsPinLogin$ | async)">
                <ng-container *ngTemplateOutlet="buttons"></ng-container>
            </ng-container>

            <ng-container *ngIf="selectedRole$ | async as role">
                <ng-container *ngIf="needsPasswordLogin$ | async">
                    <gmi-password-login-form
                        *ngIf="needsPasswordLogin$ | async"
                        [error]="error$ | async"
                        [type]="role"
                        (submit)="loginWithPassword($event)"
                    ></gmi-password-login-form>

                    <div class="mt-16" fxLayout="row" fxLayoutAlign="center center">
                        <a class="link primary-100-fg" (click)="resetSelectedRole()">{{ "features.login.back" | translate }}</a>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</gmi-public-page>

<ng-template #buttons>
    <button class="action-buttons" mat-flat-button color="accent" (click)="setSelectedRole(UserRoles.ConvenienceStore)">
        {{ "features.login.convenience_store" | translate }}
    </button>
    <button class="action-buttons" mat-flat-button color="accent" (click)="setSelectedRole(UserRoles.Kitchen)">
        {{ "features.login.kitchen" | translate }}
    </button>
    <button class="action-buttons" mat-flat-button color="accent" (click)="setSelectedRole(UserRoles.Admin)">
        {{ "features.login.admin" | translate }}
    </button>
    <button class="action-buttons" mat-flat-button color="accent" (click)="setSelectedRole(UserRoles.Commissioner)">
        {{ "features.login.commissioner" | translate }}
    </button>
</ng-template>
