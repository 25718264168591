// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    apiUrl: "https://api.mondor.recursyve.dev",
    sentry: {
        dsn: "https://98e9271168b84d569962e457c69f3c0c@o341312.ingest.sentry.io/6392709",
        environment: "staging",
        reportErrors: true
    }
};
