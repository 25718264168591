import { NiceNavigation } from "@recursyve/nice-ui-kit";

export const adminNavigation: NiceNavigation[] = [
    {
        id: "home",
        type: "group",
        children: [
            {
                id: "product",
                translate: "navigation.product",
                type: "item",
                iconType: "fontawesome",
                icon: "fas fa-shopping-cart",
                url: "/dashboard/product"
            },
            {
                id: "convenience_store",
                translate: "navigation.convenience_store",
                type: "item",
                iconType: "fontawesome",
                icon: "fas fa-store",
                url: "/dashboard/convenience-store"
            },
            {
                id: "inventory-report",
                translate: "navigation.inventory_report",
                type: "item",
                iconType: "fontawesome",
                icon: "fas fa-file-invoice",
                url: "/dashboard/inventory-report"
            },
            {
                id: "purchase-order",
                translate: "navigation.purchase_orders",
                type: "item",
                iconType: "fontawesome",
                icon: "fas fa-truck-container",
                url: "/dashboard/purchase-order"
            },
            {
                id: "kitchen",
                translate: "navigation.kitchen",
                type: "item",
                iconType: "fontawesome",
                icon: "fas fa-oven",
                url: "/dashboard/daily-order"
            },
            {
                id: "tags",
                translate: "navigation.tags",
                type: "item",
                iconType: "fontawesome",
                icon: "fas fa-tag",
                url: "/dashboard/tag"
            }
        ]
    }
];
