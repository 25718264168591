import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FilterApi } from "../../filter/filter.api";
import { Observable } from "rxjs";
import { Kitchens } from "./models/kitchens.model";

@Injectable()
export class KitchensApi extends FilterApi<Kitchens> {
    constructor(
        protected http: HttpClient
    ) {
        super("kitchen", http);
    }

    public getAll(): Observable<Kitchens[]> {
        return this.get<Kitchens[]>("");
    }
}
