import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GmiApi } from "../gmi.api";
import { InventorySettingsDto } from "./dto/inventory-settings.dto";
import { Observable } from "rxjs";
import { InventorySettingConstants } from "./models/inventory-settings.model";

@Injectable()
export class SettingsApi extends GmiApi {
    constructor(
        protected http: HttpClient
    ) {
        super("setting", http);
    }

    public update(dto: InventorySettingsDto) {
        return this.put<void>("", dto);
    }

    public getAllInventorySettingsConstants(): Observable<InventorySettingConstants> {
        return this.get<InventorySettingConstants>("inventory-snapshot");
    }
}
