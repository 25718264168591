import { HttpParams } from "@angular/common/http";

export class HttpUtils {
    public static transformQueryParams(dto: any): string {
        for (const key in dto) {
            if (!dto.hasOwnProperty(key)) {
                continue;
            }

            if (dto[key] === undefined) {
                delete dto[key];
            }
        }

        const params = new HttpParams({
            fromObject: dto
        });
        return params.toString();
    }
}
