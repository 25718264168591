import { Injectable } from "@angular/core";
import { InventorySettingsModalStore } from "./inventory-settings-modal.store";
import { NiceToastService } from "@recursyve/nice-ui-kit";
import { ApiService } from "../../../../../api/api.service";
import { InventorySettingsModalForm } from "../inventory-settings-modal.form";

@Injectable()
export class InventorySettingsModalService {
    constructor(private store: InventorySettingsModalStore, private toastService: NiceToastService, private api: ApiService) {
    }

    public reset(): void {
        this.store.reset();
    }

    public async update(form: InventorySettingsModalForm): Promise<void> {
        this.store.setLoading(true);
        try {
            await this.api.setting.update(form.toDto()).toPromise();
            this.toastService.success("", "features.dashboard.settings.inventory_snapshot.toast.update.success");
        } catch (e) {
            this.store.setError(e);
            this.toastService.error("", "features.dashboard.settings.inventory_snapshot.toast.update.failed");
        } finally {
            this.store.setLoading(false);
        }
    }

    public async loadConstants(): Promise<void> {
        this.store.setLoading(true);
        try {
            const inventorySettings = await this.api.setting.getAllInventorySettingsConstants().toPromise();
            this.store.update({
                inventorySettings
            });
        } catch (e) {
            this.store.setError(e);
        } finally {
            this.store.setLoading(false);
        }
    }

}
