import { Component, ElementRef, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../store/app.service";
import { AccountRoles } from "../../api/gmi/accounts/models/accounts.model";

@Component({
    selector: "gmi-dashboard",
    templateUrl: "dashboard.template.html",
    styleUrls: ["dashboard.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
    public AccountRoles = AccountRoles;

    constructor(
        private router: Router,
        private elementRef: ElementRef,
        private appService: AppService
    ) {}

    public ngOnInit() {
        this.appService.loadUserInfo();
        this.router.events.subscribe(_ => {
            this.elementRef.nativeElement.scrollIntoView();
        });
    }
}
