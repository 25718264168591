import { NgModule } from "@angular/core";
import { FlexModule } from "@angular/flex-layout";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { NgxMaskModule } from "ngx-mask";
import { CommonModule } from "@angular/common";
import { MatChipsModule } from "@angular/material/chips";
import { ChipListInputComponent } from "./chip-list-input.component";
import { NiceAlertModule } from "@recursyve/nice-ui-kit";

@NgModule({
    imports: [
        CommonModule,
        FlexModule,
        NgxMaskModule,
        TranslateModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatChipsModule,
        NiceAlertModule
    ],
    declarations: [
        ChipListInputComponent
    ],
    exports: [
        ChipListInputComponent
    ]
})
export class ChipListInputModule {
}
