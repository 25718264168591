import { NgModule } from "@angular/core";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { CodeScannerViewComponent } from "./code-scanner-view.component";
import { NiceFormErrorModule, NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit";
import { FlexModule } from "@angular/flex-layout";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";

@NgModule({
    imports: [
        ZXingScannerModule,
        NiceLoadingSpinnerModule,
        FlexModule,
        MatFormFieldModule,
        TranslateModule,
        NiceFormErrorModule,
        MatInputModule,
        MatDividerModule,
        MatButtonModule,
        FormsModule
    ],
    exports: [
        CodeScannerViewComponent
    ],
    declarations: [
        CodeScannerViewComponent
    ]
})
export class CodeScannerViewModule {}
