import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FilterApi } from "../../filter/filter.api";
import { ConvenienceStores } from "./models/convenience-stores.model";
import { Observable } from "rxjs";
import { ConvenienceStoresDto } from "./dto/convenience-stores.dto";
import { ConvenienceStoreExpectedProductsApi } from "./convenience-store-expected-products.api";
import { DateFilterDto } from "../inventory-snapshot/dto/inventory-snapshot.dto";
import { TrashedProducts } from "../trashed-products/models/trashed.products";
import { HttpUtils } from "../../../utils/http.utils";

@Injectable()
export class ConvenienceStoreApi extends FilterApi<ConvenienceStores> {
    constructor(
        protected http: HttpClient
    ) {
        super("convenience-store", http);
    }

    public expectedProduct(convenienceStoreId: number): ConvenienceStoreExpectedProductsApi {
        return new ConvenienceStoreExpectedProductsApi(this.http, convenienceStoreId);
    }

    public findTrashedProductByConvenienceStoreId(id: number, dto: DateFilterDto): Observable<TrashedProducts[]> {
        const params = HttpUtils.transformQueryParams(dto);
        return this.get<TrashedProducts[]>(`${id}/trashed-product?${params}`);
    }

    public isNipAlreadyUsed(nip: string): Observable<boolean> {
        return this.get<boolean>(`nip/${nip}`);
    }

    public getById(id: number): Observable<ConvenienceStores> {
        return this.get<ConvenienceStores>(`${id}`);
    }

    public getAll(): Observable<ConvenienceStores[]> {
        return this.get<ConvenienceStores[]>("");
    }

    public create(dto: ConvenienceStoresDto): Observable<ConvenienceStores> {
        return this.post<ConvenienceStores>("", dto);
    }

    public update(id: number, dto: ConvenienceStoresDto): Observable<void> {
        return this.put<void>(`${id}`, dto);
    }

    public remove(id: number): Observable<void> {
        return this.delete<void>(`${id}`);
    }

    public printQrCode(): Observable<string> {
        return this.httpClient.get(this.url("print-qr"), { responseType: "text" });
    }

    public printQrCodeById(id: number): Observable<string> {
        return this.httpClient.get(this.url(`${id}/print-qr`), { responseType: "text" });
    }
}
