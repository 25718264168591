import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../api/api.service";

export class CodeNotUsedValidator {
    public static asyncValidatorFn(apiService: ApiService, excludedId?: number): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return apiService.product.isCodeAlreadyUsed(control.value, excludedId).pipe(
                map(used => {
                    if (!used) {
                        return null;
                    }

                    return {
                        code_used: true
                    };
                })
            );
        };
    }
}
