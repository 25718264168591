<div id="public-page" fxLayout="column">
    <div id="public-page-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="public-page-content-wrapper">
            <div id="public-page-content" fxLayout="column" [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
                <div class="logo">
                    <img src="assets/images/logo-white.png" />
                </div>

                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
