import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NiceToastService } from "@recursyve/nice-ui-kit";
import { ApiService } from "../../../api/api.service";
import { PinType } from "../../../api/gmi/auth/dto/auth.dto";
import { UserRoles } from "../../../api/gmi/auth/models/user-info.model";
import { LoginStore } from "./login.store";

@Injectable()
export class LoginService {
    constructor(private store: LoginStore, private api: ApiService, private router: Router, private toast: NiceToastService) {}

    public reset(): void {
        this.store.reset();
    }

    public setSelectedRole(role: UserRoles): void {
        this.store.update({
            selectedRole: role
        });
    }

    public async loginWithNip(nip: string): Promise<void> {
        this.store.setLoading(true);
        try {
            this.resetError();
            const { selectedRole } = this.store.getValue();
            const loggedIn = await this.api.auth.loginWithPin({
                nip,
                type: selectedRole as PinType
            }).toPromise();
            if (loggedIn) {
                this.reset();
                this.router.navigate(["/dashboard"]);
            } else {
                this.toast.error("features.login.nip.not_found");
            }
        } catch (e) {
            this.store.setError(e);
        } finally {
            this.store.setLoading(false);
        }
    }

    public async loginWithPassword(email: string, password: string): Promise<void> {
        this.store.setLoading(true);
        try {
            this.resetError();
            const loggedIn = await this.api.auth.loginWithEmailPassword({
                email,
                password
            }).toPromise();
            if (loggedIn) {
                this.reset();
                this.router.navigate(["/dashboard"]);
            } else {
                this.store.setError(true);
            }
        } catch (e) {
            this.store.setError(e);
        } finally {
            this.store.setLoading(false);
        }
    }

    private resetError() {
        this.store.setError(null);
    }
}
