<mat-toolbar class="p-0" [ngClass]="toolbarClasses">
    <div class="toolbar-content" fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <ng-container *ifRole="[AccountRoles.Admin, AccountRoles.Commissioner]">
                <button mat-icon-button class="navbar-toggle-button" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>

                <div class="toolbar-separator" fxHide.gt-md></div>
            </ng-container>
            <ng-container *ifNotRole="[AccountRoles.Admin, AccountRoles.Commissioner]">
                <div class="logo">
                    <img class="logo-icon" src="assets/images/logo-small.png"/>
                </div>
            </ng-container>
        </div>

        <div class="menu-button" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="currentUser$ | async as user">
            <button mat-button disableRipple [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <ng-container *ngIf="user.account as account">
                        <img ngxGravatar [email]="account.email" class="avatar mr-0 mr-sm-16"/>
                        <span class="username mr-12" fxHide fxShow.gt-sm>
                            {{ account.email }}
                        </span>
                    </ng-container>
                    <ng-container *ngIf="user.convenienceStore as convenienceStore">
                        <span class="username mr-12">
                            {{ convenienceStore.name }}
                        </span>
                    </ng-container>
                    <ng-container *ngIf="user.kitchen as kitchen">
                        <span class="username mr-12">
                            {{ kitchen.name }}
                        </span>
                    </ng-container>
                    <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <ng-container *ifRole="[AccountRoles.Admin]">
                    <button mat-menu-item (click)="openSetting()">
                        <mat-icon>settings</mat-icon>
                        <span>{{ "features.dashboard.components.toolbar.settings" | translate }}</span>
                    </button>
                </ng-container>

                <button *ifRole="[AccountRoles.ConvenienceStore]" mat-menu-item (click)="printQrCode()" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <i class="fas fa-qrcode"></i>
                    <span>{{ "features.dashboard.components.toolbar.print_qr" | translate }}</span>
                </button>

                <button mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ "features.dashboard.components.toolbar.logout" | translate }}</span>
                </button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
