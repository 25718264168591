import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GmiApi } from "../gmi.api";
import { LoginWithEmailPasswordDto, LoginWithPinDto } from "./dto/auth.dto";
import { UserInfo } from "./models/user-info.model";

@Injectable()
export class AuthApi extends GmiApi {
    constructor(private http: HttpClient) {
        super("auth", http);
    }

    public loginWithPin(dto: LoginWithPinDto): Observable<UserInfo> {
        return this.http.post<UserInfo>(this.url("login/nip"), dto);
    }

    public loginWithEmailPassword(dto: LoginWithEmailPasswordDto): Observable<UserInfo> {
        return this.http.post<UserInfo>(this.url("login"), dto);
    }

    public getUserInfo(): Observable<UserInfo> {
        return this.http.get<UserInfo>(this.url());
    }

    public logout(): Observable<void> {
        return this.http.delete<void>(this.url());
    }
}
