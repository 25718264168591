import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { NiceToastService } from "@recursyve/nice-ui-kit";
import * as Sentry from "@sentry/angular";
import { environment } from "../../environments/environment";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private sentry: ErrorHandler;

    constructor(private injector: Injector) {
        this.sentry = Sentry.createErrorHandler({
            logErrors: true
        });
    }

    public handleError(error: Error | HttpErrorResponse) {
        const notificationService = this.injector.get(NiceToastService);

        // Hack to skip code scanning errors.
        if ((error?.message ?? "").includes("setOptions")) {
            return;
        }

        let message = error.message;
        if (environment.production) {
            message = "handler.prod_message";
        }

        notificationService.error(message);

        return this.sentry.handleError(error);
    }
}
