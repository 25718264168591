import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpUtils } from "../../../../utils/http.utils";
import { Observable } from "rxjs";
import { PurchaseOrders } from "../models/purchase-orders.model";
import { GetPurchaseOrdersDto } from "../dto/purchase-orders.dto";
import { FilterApi } from "../../../filter/filter.api";
import { PurchaseOrderProductApi } from "./purchase-order-product.api";
import { CreateConvenienceStorePurchaseOrderDto } from "../dto/create-convenience-store-purchase-orders.dto";
import { UpsertCentralPurchaseOrderDto } from "../dto/upsert-central-purchase-orders.dto";
import { DateFilterDto } from "../../inventory-snapshot/dto/inventory-snapshot.dto";
import { PurchaseOrdersDaily } from "../interfaces/purchase-orders-daily.interface";
import { ExportPurchaseOrdersDto } from "../dto/export-purchase-orders.dto";
import { PurchaseOrderProductDto } from "../../products/dto/purchase-order-product.dto";
import { PurchaseOrderProducts } from "../models/purchase-order-products.model";

@Injectable()
export class PurchaseOrderApi extends FilterApi<PurchaseOrders> {
    constructor(http: HttpClient) {
        super("purchase-order", http);
    }

    public product(purchaseOrderId: number): PurchaseOrderProductApi {
        return new PurchaseOrderProductApi(this.http, purchaseOrderId);
    }

    public getLastPurchaseOrder(): Observable<PurchaseOrders> {
        return this.get<PurchaseOrders>("central/last");
    }

    public getAll(dto: GetPurchaseOrdersDto): Observable<PurchaseOrders[]> {
        const params = HttpUtils.transformQueryParams(dto);
        return this.get<PurchaseOrders[]>(`?${params}`);
    }

    public getPurchaseOrdersToDeliver(dto: DateFilterDto): Observable<PurchaseOrdersDaily[]> {
        const params = HttpUtils.transformQueryParams(dto);
        return this.get<PurchaseOrdersDaily[]>(`to-deliver/?${params}`);
    }

    public findById(id: number): Observable<PurchaseOrders> {
        return this.get<PurchaseOrders>(`${id}`);
    }

    public submit(id: number): Observable<void> {
        return this.put<void>(`${id}`);
    }

    public setToDeliver(id: number): Observable<void> {
        return this.put<void>(`to-deliver/${id}`);
    }

    public createConvenienceStorePurchaseOrder(dto: CreateConvenienceStorePurchaseOrderDto): Observable<void> {
        return this.post("convenience-store", dto);
    }

    public submitCentralPurchaseOrder(id: number): Observable<PurchaseOrderProducts[]> {
        return this.post(`${id}/central/submit`);
    }

    public getPurchaseOrdersPrintableHtml(dto: GetPurchaseOrdersDto): Observable<string> {
        return this.httpClient.post(this.url("print/html"), dto, { responseType: "text" });
    }

    public exportAsXlsx(dto: ExportPurchaseOrdersDto): Observable<Blob> {
        return this.http.post(this.url("export/xlsx"), dto, {
            responseType: "blob"
        });
    }

    public exportById(id: number): Observable<Blob> {
        return this.http.post(this.url(`${id}/export`), {}, {
            responseType: "blob"
        });
    }

    public updatePurchaseOrder(id: number, dto: UpsertCentralPurchaseOrderDto): Observable<PurchaseOrderProductDto[]> {
        return this.put(`${id}/central`, dto);
    }
}
