import { NgModule } from "@angular/core";
import { InventorySettingsModalComponent } from "./inventory-settings-modal.component";
import { NiceFormErrorModule, NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FlexModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { InventorySettingsModalQuery } from "./store/inventory-settings-modal.query";
import { InventorySettingsModalStore } from "./store/inventory-settings-modal.store";
import { InventorySettingsModalService } from "./store/inventory-settings-modal.service";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { ChipListInputModule } from "../../../../components/chip-list-input/chip-list-input.module";

@NgModule({
    imports: [
        NiceLoadingSpinnerModule,
        MatToolbarModule,
        FlexModule,
        TranslateModule,
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        NiceFormErrorModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDatepickerModule,
        NgxMaterialTimepickerModule,
        ChipListInputModule
    ],
    declarations: [InventorySettingsModalComponent],
    providers: [
        InventorySettingsModalQuery,
        InventorySettingsModalStore,
        InventorySettingsModalService
    ],
    exports: [InventorySettingsModalComponent]
})
export class InventorySettingsModalModule {
}
