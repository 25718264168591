import { Store, StoreConfig } from "@datorama/akita";
import { InventorySettingConstants } from "../../../../../api/gmi/settings/models/inventory-settings.model";

export interface InventorySettingsModalState {
    inventorySettings: InventorySettingConstants;
}

export function createInitialState(): InventorySettingsModalState {
    return {
        inventorySettings: null,
    };
}

@StoreConfig({
    name: "inventory-settings-modal",
    resettable: true
})
export class InventorySettingsModalStore extends Store<InventorySettingsModalState> {
    constructor() {
        super(createInitialState());
    }
}
