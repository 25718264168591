import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { PurchaseOrderProducts } from "../models/purchase-order-products.model";
import { FilterApi } from "../../../filter/filter.api";
import { QuantityDto } from "../../products/dto/quantity.dto";

export class PurchaseOrderProductApi extends FilterApi<PurchaseOrderProducts> {
    constructor(http: HttpClient, id: number) {
        super(`purchase-order/${id}/product`, http);
    }

    public getAll(): Observable<PurchaseOrderProducts[]> {
        return this.get<PurchaseOrderProducts[]>();
    }

    public update(productId: number, dto: QuantityDto): Observable<void> {
        return this.put<void>(`${productId}`, dto);
    }
}
