import { NgModule } from "@angular/core";
import { CodeScannerModalComponent } from "./code-scanner-modal.component";
import { CodeScannerViewModule } from "../view/code-scanner-view.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexModule } from "@angular/flex-layout";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        CodeScannerViewModule,
        FormsModule,
        FlexModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        CommonModule
    ],
    declarations: [
        CodeScannerModalComponent
    ]
})
export class CodeScannerModalModule {}
