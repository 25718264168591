import { NgModule } from "@angular/core";
import { IfNotRoleDirective } from "./if-not-role.directive";
import { IfRoleDirective } from "./if-role.directive";
import { PreventCloseWindowDirective } from "./prevent-close-window.directive";
import { ResolveDirective } from "./resolve.directive";

@NgModule({
    declarations: [IfRoleDirective, IfNotRoleDirective, PreventCloseWindowDirective, ResolveDirective],
    exports: [IfRoleDirective, IfNotRoleDirective, PreventCloseWindowDirective, ResolveDirective]
})
export class DirectivesModule {}
