import { Accounts } from "../../accounts/models/accounts.model";
import { ConvenienceStores } from "../../convenience-stores/models/convenience-stores.model";
import { Kitchens } from "../../kitchens/models/kitchens.model";

export enum UserRoles {
    Admin = "admin",
    Commissioner = "commissioner",
    ConvenienceStore = "convenience_store",
    Kitchen = "kitchen"
}

export interface UserInfo {
    id: string;
    role: UserRoles;
    account?: Accounts;
    convenienceStore?: ConvenienceStores;
    kitchen?: Kitchens;
}
